/**************** service_part css start ****************/
.our_service {
    .section_tittle{
        @media #{$tab} {
            margin-bottom: 25px;
         }
    }
   .single_feature_text {
      h2 {
         font-size: 42px;
         line-height: 1.222;
         margin-bottom: 20px;

         @media #{$small_mobile} {
            margin-top: 0px;
            font-size: 25px;
            margin-bottom: 15px;
         }

         @media #{$large_mobile} {
            margin-top: 0px;
            font-size: 30px;
            margin-bottom: 15px;
         }

         @media #{$tab_device} {
            font-size: 30px;
         }

         @media #{$medium_device} {
            margin-top: 0px;
            font-size: 35px;
         }
      }

      p {
         line-height: 1.8;
      }
   }

   .single_service {
      padding: 46px 20px 60px;
      border: 1px solid #e8e9eb;
      text-align: center;
      @include transform_time(.6s);
      &:hover{
            box-shadow: 0px 20px 30px 0px rgba(0, 27, 94, 0.1); 
            border: 1px solid transparent;
      }
      @media #{$tab} {
         padding: 25px 10px 35px;
         margin-top: 25px;
      }

      @media #{$medium_device} {
         margin-top: 25px;
         
      }
      
      
      span {
         font-size: 50px;
         margin-bottom: 15px;
         display: inline-block;
         color: $btn_bg;
      }
      h4 {
         font-weight: 600;
         font-size: 24px;
         margin-bottom: 30px;
         @media #{$small_mobile} {
            margin-bottom: 15px;
         }
   
         @media #{$large_mobile} {
            margin-bottom: 15px;
         }
   
         @media #{$tab_device} {
            margin-bottom: 15px;
         }
   
         @media #{$medium_device} {
   
         }
      }

      p {
         color: $font_3;
         line-height: 1.8;
         font-size: 15px;
      }
   }
   .btn_3{
       margin-top: 38px;
       @media #{$tab}{
           margin-top: 15px;
       }
   }
}
.single_feature_padding{
   padding-top: 140px;
   @media #{$small_mobile}{
      padding-top: 70px;
   }
   @media #{$large_mobile}{
      padding-top: 70px;
   }
   @media #{$tab_device}{
      padding-top: 70px;
   }
   @media #{$medium_device}{
      padding-top: 70px;
   }
}
.single_page_services{
    .section_tittle{
        margin-bottom: 50px;
        @media #{$tab}{
            margin-bottom: 20px;
         }
         @media #{$medium_device}{
            margin-bottom: 20px;
         }
    }
    .single_feature{
        margin-top: 30px;
    }
}