/**************** banner part css start ****************/
.banner_part{
    height: 800px;
    position: relative;
    overflow: hidden;
    background-image: url(../img/banner_img.png);
    background-repeat: no-repeat;
    background-size: content;
    background-position:  right top;

    @media #{$tab}{
        height: 750px;
        background-image: none;
        background-color: #f7f7f7;
    }
    @media #{$medium_device}{
        height: 650px;
        background-image: none;
        background-color: #f7f7f7;
    }
    
    .banner_text{
        display: table;
        width: 100%;
        height: 800px;
        .banner_text_iner{
            display: table-cell;
            vertical-align: middle;
            @media #{$tab}{
                vertical-align: middle;
            }

        }
        @media #{$tab}{
            text-align: center;
            padding-top: 0px;
            height: 650px;
        }
        @media #{$medium_device}{
            height: 650px;
        }
        h5{
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            color: #556172;
            margin-bottom: 14px;
        }
        h1{
            font-size: 65px;
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 27px;
            line-height: 75px;
            @media #{$tab}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
        
        p{
            font-size: 15px;
            line-height: 1.8;
            font-family: $font_stack_1; 
            color: $font_7;
        }
        .btn_1{
            margin-top: 44px;
        }
    }
}
  
/**************** hero part css end ****************/
