/**************** copyright part css start ****************/
.copyright_part{
    background-color: $footer_bg;
    padding: 26px 0px;
    p{
        color: #8a8a8a;
        font-family: 300;
    }
    a{
        color: $btn_bg;
    }
    .footer-social{
        @media #{$small_mobile}{
            margin-top: 20px;
        }
        @media #{$large_mobile}{
        
        }
        @media #{$tab_device}{
        
        }
        @media #{$medium_device}{
        
        }
        a{
            width: 35px;
            height: 35px;
            display: inline-block;
            line-height: 35px;
            border: 1px solid #ff7e5f;
            text-align: center;
            margin-left: 10px;
            color: $white_color;
            
            &:hover{
                background-color: #ff7e5f !important;
                color: $white_color !important;
            }
            i{
                &:hover{
                    color: $white_color;
            }
        }
    }
   } 
   @media #{$small_mobile}{
        .footer-text{
            text-align: center;
        }
    }
    @media #{$large_mobile}{
        .footer-text{
            text-align: center;
            margin-bottom: 25px !important;
        }
    }
    @media #{$tab_device}{
        .footer-text{
            text-align: center;
            margin-bottom: 25px !important;
        }
    }
    @media #{$medium_device}{
  }
  span.ti-heart {
    font-size: 12px;
    margin: 0px 2px;
  }
}